// CreateMemberData.js
import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert,Spin, Button, Card, Form, Input, Select, message, Typography, Space } from 'antd';
import { addMemberData_API } from '../../functions/membertData';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { Title } = Typography;

const vehicleTypes = ['รถยนต์', 'รถมอเตอร์ไซค์', 'รถรับจ้าง'];

const CreateMemberData = ({ onSuccess, onCancel }) => {
    const [socket, setSocket] = useState(null);
    const [form] = Form.useForm();
    const user = useSelector(state => state.user);
    const [submitting, setSubmitting] = useState(false);
    const [houseNumberChecking, setSubmithouseNumberCheckingting] = useState(false);
    const [vehicleCheckingting, setvehicleCheckingting] = useState(false);
    const [memberChack, setMemberChack] = useState(null);
    const [vehicleChack, setVehicleChack] = useState(null);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_SOCKETIO);
        setSocket(newSocket);
        return () => newSocket.close();
    }, []);

    const onChangeNumber = async (e) => {
        const houseNumber = e.target.value.trim();
        setSubmithouseNumberCheckingting(true);

        if (!houseNumber) {
            setSubmithouseNumberCheckingting(false);
            return;
        }
        const data = {
            villageId: user.id,
            houseNumber: houseNumber
        };

        socket.emit('checkHouseNumber', data);

        // ฟังคำตอบจากเซิร์ฟเวอร์
        socket.once('getHouseNumber', (result) => {
            if (result === true) {
                setMemberChack('error')
                setSubmithouseNumberCheckingting(false);

            } else {
                setMemberChack('success')
                setSubmithouseNumberCheckingting(false);

            }
        });

    };


    const onChangeVehicleNumber = async (e) => {
        setvehicleCheckingting(true);
        const vehicleNumber = e.target.value.trim();


        if (!vehicleNumber) {
            setvehicleCheckingting(false);
            return;
        }

        const data = {
            villageId: user.id,
            vehicleNumber: vehicleNumber,
        };

        socket.emit('checkVehicleNumber', data);
        socket.once('getVehicleNumber', (result) => {
            setvehicleCheckingting(false);

            if (result) {
                setVehicleChack('error')
                setvehicleCheckingting(false)
            } else {
                setVehicleChack('success')
                setvehicleCheckingting(false)

            }
        });
    };

    // ฟังก์ชันสำหรับส่งข้อมูลไปยัง API
    const handleSubmit = async () => {
        try {
            const values = await form.validateFields(); // ตรวจสอบและรับค่าจากฟอร์ม
            const newValues = { ...values, userId: user.id };

            setSubmitting(true);
            const res = await addMemberData_API(user.token, newValues);
            message.success(res.message);

            form.resetFields(); // รีเซ็ตฟอร์มหลังจากสร้างสำเร็จ
            onSuccess(res.data); // ส่งข้อมูลกลับไปยัง Parent Component
        } catch (error) {
            console.error('Error creating member:', error);
            if (error.response?.data?.message) {
                message.error(`Error: ${error.response.data.message}`);
            } else {
                message.error('เกิดข้อผิดพลาดในการสร้างข้อมูลลูกค้า');
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                      
            <Form
                form={form}
                layout="vertical"
                name="createMemberForm"
                initialValues={{
                    vehicles: [], // เริ่มต้นด้วยรายการว่าง
                }}
                onFinish={handleSubmit}
            >
                {/* หัวข้อฟอร์ม */}
                <Title level={4} style={{ textAlign: 'center' }}>เพิ่มเลขที่บ้าน</Title>
                <Alert
                        message="หากไม่ต้องการระบุเลขที่บ้าน สามารถตั้งชื่อสำหรับเลขที่บ้านได้ตามความเหมาะสม"
                        type="info"
                        showIcon
                        className="custom-alert"
                    />
                {/* ฟิลด์สำหรับบ้านเลขที่ */}
                
                <Form.Item
                style={{marginTop:'20px'}}
                    name="houseNumber"
                    hasFeedback
                    validateStatus={memberChack}
                    help={memberChack === 'error' ? 'โปรดกรอกเลขที่บ้านที่ไม่ซ้ำกับในระบบ' : ''}
                    label="เลขที่บ้าน"
                    rules={[{ required: true, message: 'กรุณากรอกบ้านเลขที่!' }]}
                >
                    <Input
                        placeholder="กรอกเลขที่บ้าน"
                        onChange={onChangeNumber}
                        suffix={houseNumberChecking && <Spin size="small" />}
                    />
                </Form.Item>

                {/* ฟิลด์สำหรับสถานะ */}
                <Form.Item
                    name="status"
                    label="สถานะ"
                    rules={[{ required: true, message: 'กรุณาเลือกสถานะ!' }]}
                >
                    <Select placeholder="เลือกสถานะ">
                        <Option value="เจ้าของ">เจ้าของ</Option>
                        <Option value="คนเช่า">คนเช่า</Option>
                    </Select>
                </Form.Item>

                {/* ฟิลด์สำหรับการเพิ่มประเภทรถแบบไดนามิก */}
                <Form.List name="vehicles">
                    {(fields, { add, remove }) => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: 16,
                            }}
                        >
                            {fields.map((field, index) => (
                                <Card
                                    size="small"
                                    title={`รถ ${index + 1}`}
                                    key={field.key}
                                    extra={
                                        <CloseOutlined
                                            onClick={() => remove(field.name)}
                                            style={{ color: 'red', cursor: 'pointer' }}
                                        />
                                    }
                                >
                                    {/* ฟิลด์สำหรับประเภทรถ */}
                                    <Form.Item
                                        name={[field.name, 'vehicleType']}
                                        label="ประเภทรถ"
                                        rules={[{ required: true, message: 'กรุณาเลือกประเภทรถ!' }]}
                                    >
                                        <Select placeholder="เลือกประเภทรถ">
                                            {vehicleTypes.map((type) => (
                                                <Option key={type} value={type}>
                                                    {type}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    {/* ฟิลด์สำหรับหมายเลขป้ายทะเบียน */}

                                    <Form.Item
                                        name={[field.name, 'vehicleLicensePlate']}
                                        hasFeedback
                                        validateStatus={vehicleChack}
                                        help={vehicleChack === 'error' ? 'โปรดกรอกเลขทะเบียนรถไม่ซ้ำกับในระบบ' : ''}
                                        label="หมายเลขป้ายทะเบียนรถ"
                                        rules={[{ required: true, message: 'กรุณาใส่หมายเลขป้ายทะเบียนรถ!' }]}
                                    >
                                        <Input
                                            placeholder="กรอกเลขที่บ้าน"
                                            onChange={onChangeVehicleNumber}
                                            suffix={vehicleCheckingting && <Spin size="small" />}
                                        />
                                    </Form.Item>

                                    {/* ฟิลด์สำหรับจังหวัด */}
                                    <Form.Item
                                        name={[field.name, 'province']}
                                        label="จังหวัด"
                                        rules={[{ required: true, message: 'กรุณากรอกจังหวัด!' }]}
                                    >
                                        <Input placeholder="กรอกจังหวัด" />
                                    </Form.Item>
                                </Card>
                            ))}

                            {/* ปุ่มเพิ่มรถใหม่ */}
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                            >
                                เพิ่มรถ
                            </Button>
                        </div>
                    )}
                </Form.List>

                {/* ปุ่มสร้างและยกเลิก */}
                <Form.Item
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}
                >
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={submitting}
                            style={{
                                backgroundColor: '#4A4A4A', // สีเทาดำ
                                borderColor: '#4A4A4A', // ขอบสีเทาดำ
                            }}
                        >
                            เพิ่ม
                        </Button>
                        <Button
                            type="default"
                            onClick={onCancel}
                            style={{
                                backgroundColor: '#FFFFFF',
                                color: '#4A4A4A',
                                borderColor: '#4A4A4A',
                            }}
                        >
                            ยกเลิก
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};

CreateMemberData.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default CreateMemberData;
