import React, { useState, useEffect, useCallback, useMemo } from 'react';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';
import { Flex, Progress, Segmented, Space, Table, Button, message, Spin, Modal, Form, Input, Select, Row, Col, Card, Empty } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import MenuBar from '../../layout/MenuBar';
import CreateMemberData from '../../layout/CreateMemberData';
import { exportMemberData, listMemberData_API, removeMemberData_API, updateMemberData_API } from '../../../functions/membertData';

import './styles/MemberDataBase.css';

const { Column } = Table;
const { Option } = Select;

const vehicleTypes = ['รถยนต์', 'รถมอเตอร์ไซค์', 'รถรับจ้าง'];

const MemberDataBase = () => {
    const [dataMembers, setDataMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [form] = Form.useForm();
    const user = useSelector((state) => state.user);
    const idTokenResult = localStorage.getItem('token');
    const [latestModifiedData, setLatestModifiedData] = useState([]);
    const [latestDeletedData, setLatestDeletedData] = useState([]);
    const [count, setCount] = React.useState('Date');
    const [searchQuery, setSearchQuery] = useState('');
    const [exportLoading, setExportLoading] = useState(false);
    const [socket, setSocket] = useState(null);


    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_SOCKETIO);
        setSocket(newSocket);
        return () => newSocket.close();
    }, []);


    // Fetch data from API
    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const res = await listMemberData_API(idTokenResult, user.id);
            if (res.success) {
                let fetchedData = res.data.map((member) => ({
                    ...member,
                    vehicles: Array.isArray(member.vehicles) ? member.vehicles : [],
                }));

                // จัดเรียงข้อมูลตามค่าของ count
                switch (count) {
                    case 'Low': // เรียง houseNumber จากน้อยไปมาก
                        fetchedData.sort((a, b) => {
                            const houseNumberA = parseInt(a.houseNumber.match(/\d+/g)?.join('') || '0', 10);
                            const houseNumberB = parseInt(b.houseNumber.match(/\d+/g)?.join('') || '0', 10);
                            return houseNumberA - houseNumberB;
                        });
                        break;

                    case 'High': // เรียง houseNumber จากมากไปน้อย
                        fetchedData.sort((a, b) => {
                            const houseNumberA = parseInt(a.houseNumber.match(/\d+/g)?.join('') || '0', 10);
                            const houseNumberB = parseInt(b.houseNumber.match(/\d+/g)?.join('') || '0', 10);
                            return houseNumberB - houseNumberA;
                        });
                        break;

                    case 'Date': // เรียงตาม updatedAt จากใหม่ไปเก่า
                        fetchedData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                        break;

                    default:
                        break;
                }

                setDataMembers(fetchedData); // อัปเดตข้อมูลที่จัดเรียงแล้ว
            } else {
                message.error('ไม่สามารถดึงข้อมูลสมาชิกได้');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error('เกิดข้อผิดพลาดในการดึงข้อมูลสมาชิก');
        } finally {
            setLoading(false);
        }
    }, [idTokenResult, user, count]); // เพิ่ม count เป็น dependency

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Create Data success handler
    const handleCreateSuccess = useCallback(() => {
        setIsCreateModalVisible(false);
        fetchData();
    }, [fetchData]);

    // Edit Data handler
    const handleEdit = useCallback((record) => {
        setEditingRecord(record);
        setIsEditModalVisible(true);
        form.setFieldsValue({
            houseNumber: record.houseNumber,
            status: record.status,
            vehicles: record.vehicles.map((vehicle) => ({
                vehicleType: vehicle.type,
                licensePlate: vehicle.licensePlate,
                province: vehicle.province,
            })),
        });
    }, [form]);

    const handleEditConfirm = useCallback(async () => {
        try {
            const values = await form.validateFields();
            const updatedRecord = {
                ...editingRecord,
                ...values,
                vehicles: values.vehicles || [],
            };
            setEditLoading(true);
            const res = await updateMemberData_API(idTokenResult, updatedRecord);
            if (res.success) {
                message.success('แก้ไขข้อมูลสำเร็จ');
                setIsEditModalVisible(false);
                fetchData();
                // Add updated data to the latest modified list
                setLatestModifiedData([updatedRecord, ...latestModifiedData]);
            } else {
                throw new Error(res.message);
            }
        } catch (error) {
            console.error('Error editing member:', error);
            message.error('ไม่สามารถแก้ไขข้อมูลได้');
        } finally {
            setEditLoading(false);
        }
    }, [editingRecord, form, idTokenResult, fetchData, latestModifiedData]);

    // Delete Data handler
    const handleDelete = useCallback((record) => {
        Modal.confirm({
            title: 'ยืนยันการลบข้อมูล',
            content: `คุณต้องการลบข้อมูลสมาชิก: ${record.houseNumber} ใช่หรือไม่?`,
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onOk: async () => {
                try {
                    await removeMemberData_API(idTokenResult, record);
                    message.success('ลบข้อมูลสำเร็จ');
                    fetchData();
                    // Add deleted data to the latest deleted list
                    setLatestDeletedData([record, ...latestDeletedData]);
                } catch (error) {
                    console.error('Error deleting member:', error);
                    message.error('ไม่สามารถลบข้อมูลได้');
                }
            },
        });
    }, [idTokenResult, fetchData, latestDeletedData]);

    // Export Data
    const handleExportMemberData = useCallback(() => {
        Modal.confirm({
            title: 'ยืนยันการส่งออกข้อมูล',
            content: 'คุณต้องการส่งออกข้อมูลเป็นไฟล์ Excel ใช่หรือไม่?',
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onOk: async () => {
                setExportLoading(true);
                try {
                    // เรียกใช้ฟังก์ชัน exportMemberData
                    const res = await exportMemberData(idTokenResult, user);

                    console.log("Response from exportMemberData:", res); // Log ข้อมูลเพื่อดูผลลัพธ์

                } catch (error) {
                    console.error('Error exporting member data:', error);
                    message.error(`ไม่สามารถส่งออกข้อมูลได้: ${error.message}`);
                } finally {
                    setExportLoading(false);
                }
            },
        });
    }, [idTokenResult, user]);



    // Real-time Search handler
    const handleInputChange = (e) => {
        const value = e.target.value.trim();
        setSearchQuery(value);
    };

    // Filter data based on search query
    const displayedData = useMemo(() => {
        if (!searchQuery) return dataMembers;
        return dataMembers.filter(member => {
            if (member.houseNumber.includes(searchQuery)) {
                return true;
            }
            return member.vehicles.some(vehicle => vehicle.licensePlate.includes(searchQuery));
        });
    }, [dataMembers, searchQuery]);

    // Vehicle and House count calculation
    const vehicleAndHouseCounts = useMemo(() => {
        let carCount = 0;
        let motorcycleCount = 0;
        const houseNumbersSet = new Set();

        dataMembers.forEach(member => {
            if (member.houseNumber) {
                houseNumbersSet.add(member.houseNumber);
            }
            member.vehicles.forEach(vehicle => {
                if (vehicle.type === 'รถยนต์') carCount += 1;
                if (vehicle.type === 'รถมอเตอร์ไซค์') motorcycleCount += 1;
            });
        });

        const totalHouseNumbers = houseNumbersSet.size;
        const totalVehicles = carCount + motorcycleCount;

        return { carCount, motorcycleCount, totalHouseNumbers, totalVehicles };
    }, [dataMembers]);

    const { carCount, motorcycleCount, totalHouseNumbers, totalVehicles } = vehicleAndHouseCounts;

    return (
        <div>
            <MenuBar />
            <div className="functionBar">
                <div className="box-menu1">
                    <Input
                        placeholder="ค้นหาเลขทะเบียน หรือเลขที่บ้าน"
                        allowClear
                        prefix={<SearchOutlined />}
                        size="middle"
                        onChange={handleInputChange}
                        style={{ marginBottom: '20px' }}
                    />
                    {/* Status Section */}
                    <Row gutter={[16, 16]} >
                        <Col xs={24} sm={12} md={8}>
                            <Card style={{maxHeight:'95%'}}>
                                <h3>รถยนต์</h3>
                                <Progress  type="circle" percent={totalVehicles ? Math.round((carCount / totalVehicles) * 100) : 0} />
                                <p>{carCount} คัน</p>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card style={{maxHeight:'95%'}}>
                                <h3>รถมอเตอร์ไซค์</h3>
                                <Progress  type="circle" percent={totalVehicles ? Math.round((motorcycleCount / totalVehicles) * 100) : 0} />
                                <p>{motorcycleCount} คัน</p>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Card>
                                <h3>จำนวนที่อยู่อาศัยทั้งหมด</h3>
                                <h2>{totalHouseNumbers} หลัง</h2>
                            </Card>
                        </Col>
                    </Row>
                    {/* Create New Member Modal */}
                    <Modal
                        title="สร้างฐานข้อมูลลูกค้าใหม่"
                        open={isCreateModalVisible}
                        onCancel={() => setIsCreateModalVisible(false)}
                        footer={null}
                        destroyOnClose
                        width={600}
                    >
                        <CreateMemberData
                            onSuccess={handleCreateSuccess}
                            onCancel={() => setIsCreateModalVisible(false)}
                        />
                    </Modal>
                </div>

                <div className="menulist">
                    <div className="detilUser">
                        <div className="box-menu2" onClick={() => setIsCreateModalVisible(true)} style={{ cursor: 'pointer' }}>
                            เพิ่มเลขที่บ้าน
                        </div>

                        <div className="box-menu3" onClick={() => handleExportMemberData()} style={{ cursor: 'pointer' }}>
                            รายงาน
                            {exportLoading && <Spin size="small" style={{ marginLeft: '8px' }} />}
                        </div>

                        {/* Latest Modified and Deleted Data */}
                        <div className="box-menu4">
                            <Empty
                                style={{ cursor: 'pointer' }}
                                onClick={() => message.info('ยังไม่พร้อมใช้งาน')}
                            />
                        </div>


                    </div>
                </div>

                {/* Table to show Member Data */}
                <div className="table-container">

                    <div className='table-control'>
                        <Segmented
                            value={count}
                            onChange={(value) => setCount(value)}
                            options={[
                                { label: 'น้อยไปมาก', value: 'Low' },
                                { label: 'มากไปน้อย', value: 'High' },
                                { label: 'เเก้ไขล่าสุด', value: 'Date' }
                            ]}
                        />
                    </div>
                    <div className='table-list'>
                        {loading ? (
                            <div className="loading-spinner">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <Table dataSource={displayedData.map((member) => ({
                                key: member.id,
                                ...member,
                            }))}
                                rowKey="id"
                                pagination={false}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <Table
                                            columns={[
                                                { title: 'ประเภทรถ', dataIndex: 'type', key: 'type', align: 'center' },
                                                { title: 'ป้ายทะเบียนรถ', dataIndex: 'licensePlate', key: 'licensePlate', align: 'center' },
                                                { title: 'จังหวัด', dataIndex: 'province', key: 'province', align: 'center' },
                                            ]}
                                            dataSource={record.vehicles.map((vehicle) => ({
                                                ...vehicle,
                                                key: vehicle.id,
                                            }))}
                                            pagination={false}
                                        />
                                    ),
                                }}
                            >
                                <Column title="บ้านเลขที่" dataIndex="houseNumber" key="houseNumber" align="center" />
                                <Column title="สถานะ" dataIndex="status" key="status" align="center" />
                                <Column title="จำนวนรถ" key="numberVehicles" align="center"
                                    render={(text, record) => record.vehicles.length}
                                />
                                <Column
                                    title="เเก้ไข"
                                    key="actions"
                                    align="center"
                                    render={(text, record) => (
                                        <div align="right">
                                            <Space size="middle">
                                                <Button
                                                    icon={<EditOutlined />}
                                                    onClick={() => handleEdit(record)}
                                                >
                                                    แก้ไข
                                                </Button>
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => handleDelete(record)}
                                                >
                                                    ลบ
                                                </Button>
                                            </Space>
                                        </div>
                                    )}
                                />
                            </Table>
                        )}
                    </div>

                </div>
            </div>

            {/* Modal for Editing Member Data */}
            <Modal
                title="แก้ไขข้อมูลสมาชิก"
                open={isEditModalVisible}
                onOk={handleEditConfirm}
                onCancel={() => setIsEditModalVisible(false)}
                confirmLoading={editLoading}
                okText="บันทึก"
                cancelText="ยกเลิก"
                width={600}
            >
                <Form form={form} layout="vertical" className="table-ant">
                    <Form.Item
                        name="houseNumber"
                        label="บ้านเลขที่"
                        rules={[{ required: true, message: 'กรุณากรอกบ้านเลขที่!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="สถานะ"
                        rules={[{ required: true, message: 'กรุณาเลือกสถานะ!' }]}
                    >
                        <Select>
                            <Option value="เจ้าของ">เจ้าของ</Option>
                            <Option value="คนเช่า">คนเช่า</Option>
                        </Select>
                    </Form.Item>

                    <Form.List name="vehicles">
                        {(fields, { add, remove }) => (
                            <>
                                <div>ข้อมูลรถ</div>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'vehicleType']}
                                            rules={[{ required: true, message: 'กรุณาเลือกประเภทรถ!' }]}
                                        >
                                            <Select placeholder="เลือกประเภทรถ">
                                                {vehicleTypes.map((type) => (
                                                    <Option key={type} value={type}>
                                                        {type}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'licensePlate']}
                                            rules={[{ required: true, message: 'กรุณากรอกป้ายทะเบียน!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'province']}
                                            rules={[{ required: true, message: 'กรุณากรอกจังหวัด!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Button type="link" danger onClick={() => remove(name)}>
                                            ลบ
                                        </Button>
                                    </Space>
                                ))}
                                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                    เพิ่มรถใหม่
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        </div>
    );
};

export default MemberDataBase;