import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {  FaDatabase, FaSignOutAlt } from 'react-icons/fa'; // ใช้ react-icons
import './styles/MenuBar.css';

const MenuBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');
  const [current, setCurrent] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);

  useEffect(() => {
    const pathToKey = {
      '/memberdata': 'CustomerDB',
    };
    const key = pathToKey[location.pathname] || 'Dashboard';
    setCurrent(key);
  }, [location]);

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT', payload: null });
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/login');
  };

  const items = [
   
    {
      label: 'จัดการเลขทะเบียนรถ',
      key: 'CustomerDB',
      icon: <FaDatabase size={18} style={{ color: '#0f0f0f' }} />,
    },
  ];

  const onClick = (key) => {
    setCurrent(key);
    const keyToPath = {
      CustomerDB: '/memberdata',
    };
    navigate(keyToPath[key]);
  };

  return (
    <div className="menu-container">
      <nav className="custom-menu-bar">
        {items.map((item) => (
          <div
            key={item.key}
            className={`menu-item ${current === item.key ? 'selected' : ''}`}
            onClick={() => onClick(item.key)}
          >
            {item.icon}
            <span>{item.label}</span>
          </div>
        ))}
      </nav>
      {isLoggedIn && (
        <button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt size={16} style={{ marginRight: '8px' }} />
          ออกจากระบบ
        </button>
      )}
    </div>
  );
};

export default MenuBar;