import React, { useEffect } from 'react';
import './style.css';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { currentUser } from './functions/auth';
import AdminRoute from './routes/AdminRoute';
import Login from './controller/pages/auth/login';
import Register from './controller/pages/auth/register';
import MemberDataBase from './controller/pages/admin/memberDataBase';


function App() {
  const dispatch = useDispatch();
  useEffect(() => {

    const idTokenResult = localStorage.getItem('token');  // ดึง token จาก localStorage
    const autoLogin = localStorage.getItem('autoLogin');  // ดึง token จาก localStorage
    if (idTokenResult) {
      currentUser(idTokenResult)  // เรียกฟังก์ชันเพื่อรับข้อมูลผู้ใช้จาก token
        .then(res => {
          dispatch({
            type: 'LOGGED_IN_USER',
            payload: {
              id: res.data.id,
              village: res.data.village,
              role: res.data.role,
              autoLogin:autoLogin,
              token: idTokenResult,
            },
          });
        })
        .catch(err => {
          dispatch({
            type: 'LOGOUT',
            payload: null,
          });
          console.log(err);
        });
    } else {
      // getUserToken().then((res)=>{
      //   console.log(res)
      // })
      dispatch({
        type: 'LOGOUT',
        payload: null,
      });
    }
  }, [dispatch]);  // เพิ่ม user?.autoLogin ใน dependency array เพื่อทำงานเมื่อเปลี่ยนค่า
  return (
   <>
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/memberdata" element={<AdminRoute><MemberDataBase /></AdminRoute>} />
      
      </Routes>
    </div>
   </>
  );
}

export default App;
