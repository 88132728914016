import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { LoginHeadler } from '../../../functions/auth';
import '../admin/styles/Login.css'; // ใช้ไฟล์ CSS แยกเพื่อการจัดสไตล์ที่ง่าย

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    const payload = {
      ...values,
      phase: parseInt(values.phase, 10),
    };

    LoginHeadler(payload)
      .then((res) => {
        const { id, village, role, autoLogin } = res.data.payload;
        const { token } = res.data;

        dispatch({
          type: 'LOGGED_IN_USER',
          payload: {
            id,
            village,
            role,
            token,
            autoLogin,
          },
        });

        localStorage.setItem('token', token);
        localStorage.setItem('autoLogin', autoLogin);

        roleBasedRedirect(role);
        message.success('เข้าสู่ระบบสำเร็จ!');
      })
      .catch((err) => {
        console.error('Login error:', err);
        message.error(err.response?.data?.message || 'เกิดข้อผิดพลาด กรุณาลองอีกครั้ง!');
      });
  };

  const roleBasedRedirect = (role) => {
    if (role === 'admin') {
      navigate('/memberdata'); // Admin path
    } else {
      navigate('/'); // Default path
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <h2 className="login-heading">ลงชื่อเข้าใช้</h2>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="หมู่บ้าน"
            name="village"
            rules={[{ required: true, message: 'กรุณาใส่ชื่อหมู่บ้าน' }]}
          >
            <Input className="input-field" placeholder="กรุณาระบุชื่อหมู่บ้าน" />
          </Form.Item>

          <Form.Item
            label="เฟส"
            name="phase"
            rules={[
              { required: true, message: 'กรุณาระบุเฟสหมู่บ้าน!' },
              { pattern: /^[1-9]\d*$/, message: 'กรุณาใส่เฉพาะตัวเลขที่มากกว่า 0!' },
            ]}
          >
            <Input className="input-field" placeholder="กรุณาระบุเฟสหมู่บ้าน" />
          </Form.Item>

          <Form.Item
            label="รหัสผ่าน"
            name="password"
            rules={[{ required: true, message: 'กรุณาใส่รหัสผ่านของคุณ!' }]}
          >
            <Input.Password className="input-field" placeholder="กรุณาระบุรหัสผ่าน" />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit" className="submit-button">
              เข้าสู่ระบบ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;